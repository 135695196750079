<template>
    <NuxtLink class="promo__button btn btn--primary btn--black-hover cta" :to="linkedTargetUrl"
        v-bind:[attributes.itemId]="model?.system.id" v-bind:[attributes.elementCodename]="type.title.codename"
        :target="newTab ? '_blank' : '_self'">{{
            model?.elements.title.value
        }}</NuxtLink>
</template>

<script setup lang="ts">
import { contentTypes } from '~/models';
const siteStore = useSiteStore()
const attributes = GetKontentAttributes();

const type = contentTypes.call_to_action.elements;

import type { CallToActionModel } from '~/models'
const props = defineProps<{ model: CallToActionModel }>();
const newTab = ref(false)
const linkedTargetUrl = ref<string>('');

linkedTargetUrl.value = getRef(props.model)

function getRef(model: CallToActionModel): string {
    if (model?.elements?.linkedTarget?.value.length > 0) {
        const codename = model.elements.linkedTarget.value[0]
        return siteStore.getUrlByCodename(codename)
    }

    if (model?.elements?.manualTarget?.value) {
        return model.elements.manualTarget.value
    }

    if (model?.elements?.genericAssetTarget.value.length > 0) {
        const asset = model.elements.genericAssetTarget.value[0]
        newTab.value = true
        return asset.url
    }

    // This should be the default value/route or stuff like that.
    return '/';
}


function showEmailAddress(model: CallToActionModel): boolean {
    return !!model.elements.emailAddress;
}
</script>

<style lang="scss" scoped>
@use "~/assets/scss/btn.scss";
@use "~/assets/scss/button.scss";

.promo-button {
    margin-bottom: 0;
}
</style>